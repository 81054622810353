#signature-modal {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

#signature-modal .modal-dialog {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
}

#signature-modal .modal-content {
  height: 100%;
  border: none;
}

#signature-modal .modal-content .modal-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  border: none;
  z-index: 2000;
}

#signature-modal .modal-content .modal-body {
  height: 100%;
  padding: 0;
}

#signature-modal .modal-content .modal-header .modal-title {
  color: #000;
  font-size: 1.2rem;

}

#signature-modal .modal-content .modal-header .close {
  background-color: #6c757d;
  color: #fff;
  font-size: 2rem;
  opacity: 1;
}

#signature-modal .modal-content .modal-body .modal-body-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#signature-pad {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ececec;
}

#signature-pad .signature-container {
  position: relative;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 90%;
  height: auto;
  max-height: 90%;
  aspect-ratio: 2/1;

  &::after {
    content: '';
    position: absolute;
    bottom: 15%;
    left: 0;
    width: 90%;
    height: 1px;
    margin: 0 5%;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
}

#signature-pad .signature-container canvas {
  width: 100%;
  height: 100%;
}

#signature-pad .controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

#signature-pad .controls .btn {
  border: none;
}