@import 'bootstrap-variables';
@import 'bootstrap';
@import 'bootstrap-select/dist/css/bootstrap-select';
@import 'tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4.scss';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'layouts/application';
@import 'layouts/devise';
@import 'layouts/pdf';
@import 'fonts';
@import 'datepicker';
@import 'tinymce/skins/ui/oxide/skin.min.css';
@import 'consumption_chart.scss';
@import 'storage_transaction.scss';
@import 'order_delivery.scss';
@import "./actiontext.scss";
@import 'camera_scanner.scss';
@import 'photo_capture.scss';
@import 'signature_capture.scss';
@import 'tinymce.scss';

html {
  font-size: 13px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #707070;
  background-color: #f3f3f3;
}

.form-control.is-valid {
  border-color: rgb(206, 212, 218) !important;
  background-image: none !important;
}

.form-control.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25) !important;
}

#any_login span.text {
  display: inline-block !important;
}

.navigation .navigation {
  margin-bottom: 0px !important;
  margin-left: 20px;
}

.btn-primary {
  color: #fff;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid rgb(69, 176, 255);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-checkbox input,
.custom-checkbox label {
  cursor: pointer;
}

.fa.btn, .fas.btn {
  font-weight: inherit;
}

#connection-status {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
}

#connection-status.connected {
  background-color: #57d59f;
}

#connection-status.connected .label {
  color: #fff;
}

#connection-status.disconnected {
  background-color: rgb(248, 214.6, 217.8);
}

#connection-status.disconnected .label {
  color: rgb(114.4, 27.56, 35.88);
}